import React from "react"
import {animated, useTransition} from "@react-spring/web";
import {VscChromeClose} from "react-icons/vsc";

export default function Sidebar({mountCondition, setMountCondition, children, title}) {
    const sidebarTransitions = useTransition(mountCondition, {
        from: {transform: "translateX(-800px)", "minWidth": 0, width: 0,},
        enter: {transform: "translateX(0px)", "minWidth": 400, width: 400},
        leave: {transform: "translateX(-800px)", "minWidth": 0, width: 0},
    })

    return (
        sidebarTransitions((styles, condition) => condition &&
            <animated.div style={styles}
                          className={`sidebar card position-relative d-flex ${mountCondition && "me-3"}`}>
                <div className={'d-flex m-3 justify-content-between'}>
                    <h3 className={'p-2 ps-0 m-0'}>{title}</h3>
                    <button className={'btn btn-light rounded-5'}
                            onClick={() => setMountCondition(false)}>
                        <VscChromeClose size={25} color={'light'}/>
                    </button>
                </div>
                <div className={'card-body position-relative p-0'}>
                    <div className={'position-absolute top-0 start-0 end-0 bottom-0 overflow-auto rounded-3 ms-3 pe-3 mb-3'}>
                        {children}
                    </div>
                </div>
            </animated.div>
        )
    )
}