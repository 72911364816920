import {NODE_RADIUS} from "./graphfunctions";
import {operationTypes} from "../Operations";

const dot = (color, size) => (
    `<svg xmlns="http://www.w3.org/2000/svg" style="" class="bi bi-dot" width="${size}" height="${size}" fill="${color}" viewBox="0 0 ${size} ${size}">
          <path transform="scale(${size/16}, ${size/16})" d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
     </svg>`
)

const addNode = (color, size) => (
    `<svg xmlns="http://www.w3.org/2000/svg" class="bi bi-node-plus" width="${size}" height="${size}" stroke="${color}" fill="none" stroke-width="0" viewBox="0 0 ${size} ${size}">
        <path transform="scale(${size/24}, ${size/24})" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
    </svg>`
)

const delNode = (color, size) => (
    `<svg xmlns="http://www.w3.org/2000/svg" class="bi bi-node-plus" width="${size}" height="${size}" 
            stroke="${color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
            fill="none" viewBox="0 0 ${size} ${size}">
      <path transform="scale(${size/24}, ${size/24})" d="M20.042 16.045A9 9 0 0 0 7.955 3.958M5.637 5.635a9 9 0 1 0 12.725 12.73M3 3l18 18"/>
    </svg>`
)

const addEdge = (color, size) => (
    `<svg xmlns="http://www.w3.org/2000/svg" class="bi bi-node-plus" 
            width="${size}" height="${size}" stroke-linecap="round" 
            stroke-linejoin="round" viewBox="0 0 ${size} ${size}"
            stroke="${color}" fill="none" stroke-width="2">
      <circle transform="scale(${size/24}, ${size/24})" cx="6" cy="18" r="3"/>
      <circle transform="scale(${size/24}, ${size/24})" cx="18" cy="6" r="3"/>
      <line transform="scale(${size/24}, ${size/24})" x1="8.5" y1="15.5" x2="15.5" y2="8.5"/>
      <path transform="scale(${size/24}, ${size/24})" d="M15 18h6m-3 -3v6"/>
    </svg>`
)

const delEdge = (color, size) => (
    `<svg xmlns="http://www.w3.org/2000/svg" class="bi bi-node-plus" 
            width="${size}" height="${size}" stroke-linecap="round" 
            stroke-linejoin="round" viewBox="0 0 ${size} ${size}"
            stroke="${color}" fill="none" stroke-width="2">
      <circle transform="scale(${size/24}, ${size/24})" cx="6" cy="18" r="3"/>
      <circle transform="scale(${size/24}, ${size/24})" cx="18" cy="6" r="3"/>
      <line transform="scale(${size/24}, ${size/24})" x1="14" y1="10" x2="15.5" y2="8.5"/>
      <line transform="scale(${size/24}, ${size/24})" x1="8.5" y1="15.5" x2="10" y2="14"/>
      <path transform="scale(${size/24}, ${size/24})" d="M3 3l18 18"/>
    </svg>`
)


const flipEdge = (color, size) => (
    `<svg xmlns="http://www.w3.org/2000/svg" class="bi bi-node-plus" 
            width="${size}" height="${size}" stroke-linecap="round" 
            stroke-linejoin="round" viewBox="0 0 ${size} ${size}"
            stroke="${color}" fill="none" stroke-width="2">
      <line transform="scale(${size/24}, ${size/24})" stroke="#EA4335" x1="8.5" y1="15.5" x2="15.5" y2="8.5"/>
      <circle transform="scale(${size/24}, ${size/24})" cx="6" cy="18" r="3"/>
      <circle transform="scale(${size/24}, ${size/24})" cx="18" cy="6" r="3"/>
      <path transform="scale(${size/24}, ${size/24})" d="M15 17a3 3 0 1 1 .5 3m-.3 1.5v-1.5h1.5"/>
    </svg>`
)


const merge = (color, size) => (
    `<svg xmlns="http://www.w3.org/2000/svg" class="bi bi-node-plus" width="${size}" height="${size}" fill="${color}" viewBox="0 0 ${size} ${size}">
        <path transform="scale(${size/24}, ${size/24})" d="M5.559 8.855c.166 1.183.789 3.207 3.087 4.079C11 13.829 11 14.534 11 15v.163c-1.44.434-2.5 1.757-2.5 3.337 0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5c0-1.58-1.06-2.903-2.5-3.337V15c0-.466 0-1.171 2.354-2.065 2.298-.872 2.921-2.896 3.087-4.079C19.912 8.441 21 7.102 21 5.5 21 3.57 19.43 2 17.5 2S14 3.57 14 5.5c0 1.552 1.022 2.855 2.424 3.313-.146.735-.565 1.791-1.778 2.252-1.192.452-2.053.953-2.646 1.536-.593-.583-1.453-1.084-2.646-1.536-1.213-.461-1.633-1.517-1.778-2.252C8.978 8.355 10 7.052 10 5.5 10 3.57 8.43 2 6.5 2S3 3.57 3 5.5c0 1.602 1.088 2.941 2.559 3.355zM17.5 4c.827 0 1.5.673 1.5 1.5S18.327 7 17.5 7 16 6.327 16 5.5 16.673 4 17.5 4zm-4 14.5c0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5.673-1.5 1.5-1.5 1.5.673 1.5 1.5zM6.5 4C7.327 4 8 4.673 8 5.5S7.327 7 6.5 7 5 6.327 5 5.5 5.673 4 6.5 4z"></path>
     </svg>`
)

export default function getSVG(type, color, size) {
    switch (type) {
        case operationTypes.ADD_NODE :
            return addNode(color, size);
        case operationTypes.DELETE_NODE :
            return delNode(color, size);
        case operationTypes.ADD_EDGE :
            return addEdge(color, size);
        case operationTypes.DELETE_EDGE :
            return delEdge(color, size);
        case operationTypes.FLIP_EDGE :
            return flipEdge(color,size);
        case operationTypes.MERGE:
            return merge(color,size);
        default :
            return dot(color, size);
    }
}