import React, {useContext} from "react";
import {SettingsContext} from "../../contexts/SettingsContext";

export default function Settings() {
    const {forceLayoutActive, fixOnDrag, setForceLayoutActive, setFixOnDrag} = useContext(SettingsContext)
    return (
        <div>
            <div className={'d-flex justify-content-between bg-secondary rounded mb-1' }>
                <p className={'m-auto ms-2'}>Fix node in place after drag</p>
                <div className="m-2 form-check form-switch">
                    <input checked={fixOnDrag} onChange={() => setFixOnDrag(v => !v)}
                           className="form-check-input" type="checkbox" id={`setting_drag`}/>
                </div>
            </div>
            <div className={'d-flex justify-content-between bg-secondary rounded' }>
                <p className={'m-auto ms-2'}>Active force layout</p>
                <div className="m-2 form-check form-switch">
                    <input checked={forceLayoutActive} onChange={() => setForceLayoutActive(v => !v)}
                           className="form-check-input" type="checkbox" id={`setting_forceLayout`}/>
                </div>
            </div>
        </div>
    )
}