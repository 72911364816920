import React, {useEffect, useRef, useState} from "react"
import {ForceGraph2D} from 'react-force-graph';
import useKeyPress from "../../hooks/useKeyPress";
import {useResizeDetector} from "react-resize-detector";
import {NODE_RADIUS, paintHistoryNode} from "../utils/graphfunctions";

export default function History({
                                    historyOperations,
                                    currentHistoryNodeId,
                                    setCurrentHistoryNodeId,
                                    setData
                                }) {
    const fgRef = useRef();
    const [center, setCenter] = useState({x: 0, y: 0})
    const [historyTree, setHistoryTree] = useState({nodes: [], links: []});
    const nodeSpread = 30;

    function changeCurrentHistoryNode(historyNodeId) {
        if (historyNodeId !== undefined && historyNodeId !== -1) {
            setCurrentHistoryNodeId(historyNodeId);
            setData(historyOperations[historyNodeId].data);
        }
    }

    const handleNodeClick = node => {
        if (node)
            changeCurrentHistoryNode(node.id);
    };

    useEffect(() => {
        let newTree = {nodes: [], links: []};
        let nodeMap = new Map(); // {id: {children: [array of children ids], parent: id}}
        historyOperations.forEach((item) => nodeMap.set(item.id, {
            children: [],
            parent: item.par,
            name: item.operation.name(),
            type: item.operation.type()
        }))
        nodeMap.forEach((node,id) => {
            if (node.parent !== -1)
                nodeMap.get(node.parent).children.push(id);
        })

        function addNodeToTree(me, parent, x, y) {
            let newNode = {id: me, fx: x, fy: y, name: nodeMap.get(me).name,type:nodeMap.get(me).type};
            if(currentHistoryNodeId === me)
            {
                newNode.selected = true
                setCenter({x: x, y: y})
            }
            newTree.nodes.push(newNode)
            if (parent !== null) newTree.links.push({'source': parent, 'target': me})
            let max_x = x;
            let count = 0
            nodeMap.get(me).children.forEach((child)=>{
                let tmp_x = addNodeToTree(child,me,max_x+(count !==0 ? nodeSpread:0),y+nodeSpread)
                count += 1
                max_x = Math.max(tmp_x,max_x)
            })
            return max_x
        }
        addNodeToTree(0,null,0,0)
        setHistoryTree(newTree);
    }, [historyOperations, currentHistoryNodeId]);

    const backspacePress = useKeyPress('Backspace');
    useEffect(() => {
        if (backspacePress === true) {
            changeCurrentHistoryNode(historyOperations[currentHistoryNodeId].par);
        }
    }, [backspacePress]);

    const [historyWidth, setHistoryWidth] = useState(0);
    const [historyHeight, setHistoryHeight] = useState(0);
    const {ref, width, height} = useResizeDetector();

    useEffect(() => {
        const timer = setTimeout(() => {
            setHistoryWidth(width)
            setHistoryHeight(height)
        }, 200);
        return () => clearTimeout(timer);
    }, [height, width])


    useEffect(() => {
        fgRef.current.centerAt(center.x, center.y, 500)
    }, [center, []])
    return (
        <div className={'card position-absolute top-0 bottom-0 start-0 end-0 me-3'} ref={ref}>
            <ForceGraph2D
                ref={fgRef}
                width={historyWidth}
                height={historyHeight}
                graphData={historyTree}
                nodeRelSize={NODE_RADIUS}
                autoPauseRedraw={false}
                linkWidth={2}
                nodeCanvasObject={(node, ctx) => paintHistoryNode(node, ctx, currentHistoryNodeId)}
                onNodeClick={handleNodeClick}
                enableNodeDrag={false}
            />
        </div>

    )
};

