import React from 'react';
import { Modal } from 'react-bootstrap';
import Draggable from 'react-draggable';

const DraggableModal = ({ title, children, ...rest }) => {
    return (
        <Modal {...rest}>
            <Draggable handle=".modal-header">
                <div className="modal-content">
                    <div className="modal-header">
                        <Modal.Title>lorem ipsum{title}</Modal.Title>
                    </div>
                    <div className="modal-body">lorem ipsum{children}</div>
                </div>
            </Draggable>
        </Modal>
    );
};

export default DraggableModal;