import React, {useState} from "react"
import axios from "axios"
import {MergeOperation,Colors} from './Operations'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'http://localhost:3001';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default function Solvers({data, applyAndSaveOperations}) {
    const [waiting, setWaiting] = useState(false);



    const handleSolve = async () => {
        const promiseTimeout = new Promise((resolve) => (setTimeout(() => resolve(false), 10000)))
        const solverData = {
            'graph': {
                'n': data.nodes.length,
                'edges': data.links.filter(e => e.color === Colors.black).map(e => [e.source.id, e.target.id]),
                'red_edges': data.links.filter(e => e.color === Colors.red).map(e => [e.source.id, e.target.id]),
            }
        }

        setWaiting(true);
        try {
            const r = await Promise.race([promiseTimeout, axios.post('/solve', solverData)])
            if (!r)
                throw new Error("request timeout")
            // merge[1] and merge[0] are flipped because backend returns them in order (delete,retain)
            applyAndSaveOperations(r.data.merges.map(merge => new MergeOperation(merge[1], merge[0])))
        } catch (e) {
            console.error(e)
        } finally {
            setWaiting(false);
        }
    };

    return (
        <div>
            <button className={'btn btn-primary'}
                    onClick={handleSolve}
                    disabled={waiting}>
                {
                    waiting
                        ? <div className="spinner">
                            <div className="bounce1"/>
                            <div className="bounce2"/>
                            <div className="bounce3"/>
                        </div>
                        : <strong>Solve</strong>
                }
            </button>
        </div>

    );
}
