import React, {useMemo} from "react";
import {TiFlowMerge} from "react-icons/ti";
import getSVG from "./nodeSvg";

export const NODE_RADIUS = 8;

export function paintGraphNode(node, ctx, selectedId) {
    ctx.beginPath();
    ctx.arc(node.x, node.y, NODE_RADIUS * 1.3, 0, 2 * Math.PI, false);
    ctx.fillStyle = '#202124';
    ctx.fill();

    ctx.beginPath();
    ctx.arc(node.x, node.y, NODE_RADIUS, 0, 2 * Math.PI, false);
    ctx.fillStyle = selectedId === node.id ? '#88B4F7' : 'white';
    ctx.fill();

    ctx.fillStyle = '#202124'
    ctx.font = '10px Roboto';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(node.id, node.x, node.y);
};

export function paintHistoryNode(node, ctx, selectedId) {
    ctx.innerhtml = "<TiFlowMerge/>"
    ctx.beginPath();
    ctx.arc(node.x, node.y, NODE_RADIUS * 1.2, 0, 2 * Math.PI, false);
    ctx.fillStyle = '#202124';
    ctx.fill();
    //
    ctx.fillStyle = selectedId === node.id ? '#88B4F7' : '#DADCE0';
    ctx.beginPath();
    ctx.arc(node.x, node.y, NODE_RADIUS, 0, 2 * Math.PI, false);
    ctx.fill();

    const image = new Image();
    image.src = `data:image/svg+xml;base64,${window.btoa(
        getSVG(node.type,
            '#202124',NODE_RADIUS*1.5)
    )}`;
    ctx.drawImage(image, node.x - image.width / 2, node.y - image.height / 2);
    //
    // ctx.fillStyle = 'blue';
    // ctx.font = '5px Sans-Serif';
    // ctx.textAlign = 'center';
    // ctx.textBaseline = 'middle';
    // ctx.fillText(node.name, node.x, node.y);
};