import React, {useEffect, useState} from "react";
import './css/custom.scss'
import {AiFillSetting} from "react-icons/ai";
import {FaHistory, FaPlus, FaTimes} from "react-icons/fa";
import {BiInfoCircle} from "react-icons/bi";
import {SettingsContext} from "./contexts/SettingsContext";
import 'bootstrap/dist/js/bootstrap.js';
import FileUpload from "./components/FileUpload";
import Solvers from "./components/Solvers";
import Graph from "./components/Graph";
import History from "./components/sidebar/History";
import Settings from "./components/sidebar/Settings";
import {AddEdgeOperation, AddNodeOperation, NoOperation} from "./components/Operations";
import Info from "./components/sidebar/Info";
import Sidebar from "./components/sidebar/Sidebar";
import {BsThreeDots} from "react-icons/bs";
import DraggableModal from "./components/modal/DraggableModal";
import {Button, Modal} from 'react-bootstrap';


window.VERSION = process.env.REACT_APP_VERSION

// TODO settings provider node radius setting

const SidebarSites = {
    History: 'History',
    Settings: 'Settings',
    Info: 'Info'
}

function App() {
    const [fixOnDrag, setFixOnDrag] = useState(false);
    const [forceLayoutActive, setForceLayoutActive] = useState(true);
    const [actionModal, setActionModal] = useState(false);


    const [data, setData] = useState({nodes: [], links: []});
    const [zoom, setZoom] = useState({k: 1, x: 0, y: 0});
    const [firstFreeId, setFirstFreeId] = useState(0);
    const [historyOperations, setHistoryOperations] = useState([{
        id: 0,
        operation: new NoOperation(),
        par: -1,
        data: data
    }]);
    const [totalHistorySteps, setTotalHistorySteps] = useState(1);
    const [currentHistoryNodeId, setCurrentHistoryNodeId] = useState(0);

    function applyAndSaveOperations(newOperations) {
        let draftData = data;
        let lastHistoryNodeId = currentHistoryNodeId;
        let lastHistoryStep = totalHistorySteps;
        newOperations.forEach(operation => {
            operation.apply(draftData, (updatedDraft) => draftData = updatedDraft);
            const newHistoryOperation = {
                id: lastHistoryStep,
                operation: operation,
                par: lastHistoryNodeId,
                data: {...draftData},
            };
            lastHistoryNodeId = newHistoryOperation.id;
            lastHistoryStep += 1;
            historyOperations.push(newHistoryOperation);
        });
        const newData = {...draftData};
        setHistoryOperations([...historyOperations]);
        setCurrentHistoryNodeId(historyOperations[historyOperations.length - 1].id);
        setTotalHistorySteps(lastHistoryStep);
        setData(newData);
    }

    const loadFile = (content) => {
        const lines = content.split(/\n/);
        const [_a, _b, nodesStr, edgesStr] = lines[0].split(/ /);
        const [nodes, edges] = [parseInt(nodesStr), parseInt(edgesStr)];
        let operations = [];
        for (let i = 0; i < nodes; ++i) {
            operations.push(new AddNodeOperation(firstFreeId + i, 0, 0));
        }
        for (let i = 0; i < edges; ++i) {
            const line = lines[i + 1];
            const [frStr, toStr] = line.split(/ /);
            const [fr, to] = [parseInt(frStr), parseInt(toStr)];
            operations.push(new AddEdgeOperation(firstFreeId + fr - 1, firstFreeId + to - 1));
        }
        applyAndSaveOperations(operations);
        setFirstFreeId(firstFreeId + nodes);
    };


    function applyAndSaveOperation(newOperation) {
        applyAndSaveOperations([newOperation]);
    }

    function clear() {
        setData({nodes: [], links: []})
        setZoom({k: 1, x: 0, y: 0});
        setHistoryOperations([{id: 0, operation: new NoOperation(), par: -1, data: data}]);
        setTotalHistorySteps(1);
        setCurrentHistoryNodeId(0);
        setFirstFreeId(0);
    }

    const [sidebarActive, setSidebarActive] = useState(true);
    const [sidebarSite, setSidebarSite] = useState(SidebarSites.History);

    useEffect(() => {
        if (!sidebarSite && sidebarActive)
            setSidebarActive(false);
        else if (sidebarSite && !sidebarActive)
            setSidebarActive(true);
    }, [sidebarSite])
    useEffect(() => {
        if (!sidebarActive && sidebarSite)
            setSidebarSite(null);
        else if (sidebarActive && !sidebarSite)
            setSidebarActive(false);
    }, [sidebarActive])

    return (
        <SettingsContext.Provider value={{fixOnDrag, setFixOnDrag, forceLayoutActive, setForceLayoutActive}}>
            <div className={`vh-100 d-flex flex-column p-3 bg-dark`}>
                {/*<Navbar data={data} apply={applyAndSaveOperations} load={loadFile} clear={handleClear}/>*/}
                <div className="d-flex flex-grow-1">
                    <Sidebar mountCondition={sidebarActive}
                             setMountCondition={setSidebarActive}
                             title={sidebarSite}>
                        {
                            sidebarSite === SidebarSites.History &&
                            <History
                                historyOperations={historyOperations}
                                currentHistoryNodeId={currentHistoryNodeId}
                                setCurrentHistoryNodeId={setCurrentHistoryNodeId}
                                setData={setData}
                            />
                        }
                        {
                            sidebarSite === SidebarSites.Settings &&
                            <Settings/>
                        }
                        {
                            sidebarSite === SidebarSites.Info &&
                            <div>
                                <Info/>
                            </div>
                        }
                    </Sidebar>
                    <Graph data={data} apply={applyAndSaveOperation} id={firstFreeId}
                           setId={setFirstFreeId}/>

                </div>
                <div className={'d-flex justify-content-between m-3 mb-0'}>
                    <div className={'d-inline-flex gap-2'}>
                        <button type="button"
                                className={`btn p-2 rounded-5 ${sidebarSite === SidebarSites.History ? "btn-info" : "btn-dark"}`}
                                onClick={() => setSidebarSite(v => v !== SidebarSites.History ? SidebarSites.History : null)}>
                            <FaHistory color={'primary'} size={25}/>
                        </button>
                        <button type="button"
                                className={`btn p-2 rounded-5 ${sidebarSite === SidebarSites.Settings ? "btn-info" : "btn-dark"}`}
                                onClick={() => setSidebarSite(v => v !== SidebarSites.Settings ? SidebarSites.Settings : null)}>
                            <AiFillSetting color={'primary'} size={25}/>
                        </button>
                        <button type="button"
                                className={`btn p-2 rounded-5 ${sidebarSite === SidebarSites.Info ? "btn-info" : "btn-dark"}`}
                                onClick={() => setSidebarSite(v => v !== SidebarSites.Info ? SidebarSites.Info : null)}>
                            <BiInfoCircle color={'primary'} size={25}/>
                        </button>
                    </div>
                    <div className={'d-flex m-auto align-items-center'}>
                        <div className={'me-2 d-none d-md-block'}>
                            <FileUpload callback={loadFile}/>
                        </div>
                        <div className={'me-2 d-none d-md-block'}>
                            <Solvers data={data} applyAndSaveOperations={applyAndSaveOperations}/>
                        </div>
                        <div className={'me-2'}>
                            <button className={'btn btn-danger'} onClick={clear}>
                                <div className={"d-flex align-items-center"}>
                                    <FaTimes size={15}/>
                                    <div className={"ms-1 d-none d-sm-block text-nowrap"}><strong>Clear</strong></div>
                                </div>
                            </button>
                        </div>
                        <div className={'me-2 d-block d-sm-block d-md-none'}>
                            <Button variant="primary" onClick={() => setActionModal(true)}>
                                <div className={"d-flex align-items-center"}>
                                    <BsThreeDots size={25}/>
                                    <div className={"ms-1 d-none d-sm-block text-nowrap"}><strong>More</strong></div>
                                </div>
                            </Button>

                            <Modal show={actionModal} onHide={() => setActionModal(false)} centered>
                                <Modal.Header closeButton/>
                                <Modal.Body closeButton>
                                    <FileUpload callback={loadFile}/>
                                </Modal.Body>
                            </Modal>
                        </div>
                        <div>
                            <button className={'btn btn-primary'}
                                    onClick={() => {
                                        applyAndSaveOperation(new AddNodeOperation(firstFreeId, zoom.x, zoom.y));
                                        setFirstFreeId(prev => prev + 1);
                                    }}>
                                <div className={"d-flex align-items-center"}>
                                    <FaPlus size={15}/>
                                    <div className={"ms-1 d-none d-sm-block text-nowrap"}><strong>Add node</strong>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className={'m-auto me-0 ms-2'}>
                        <p className={'text-end d-none d-lg-block font-monospace text-secondary m-0 text-nowrap'}>Twin-width
                            visualizer {window.VERSION}</p>
                    </div>
                    <DraggableModal/>


                </div>
            </div>
        </SettingsContext.Provider>
    );
}


export default App;
