
export const operationTypes = {
    INIT : 0,
    ADD_NODE: 1,
    DELETE_NODE:2,
    ADD_EDGE:3,
    DELETE_EDGE:4,
    FLIP_EDGE:5,
    MERGE:6
}

export const Colors = {
    black: '#202124',
    red: '#EA4335'
}

export class NoOperation {
    name() { return 'history start' }
    type() { return operationTypes.INIT}
    apply(data, setData) { }
}

export class AddNodeOperation {
    constructor(id, x, y) {
        this.id = id;
        this.x = x;
        this.y = y;
    }
    name() { return `added node ${this.id}` }
    type() { return operationTypes.ADD_NODE}
    apply(data, setData) {
        setData({nodes: [{id: this.id, x: this.x, y: this.y}, ...data.nodes], links: data.links});
    }
}

export class DeleteNodeOperation {
    constructor(node) {
        this.node = node;
    }
    name() { return `deleted node ${this.node.id}` }
    type() { return operationTypes.DELETE_NODE}
    apply(data, setData) {
        if (this.node) {
            setData({
                nodes: data.nodes.filter(n => n !== this.node),
                links: data.links.filter(l => l.source !== this.node && l.target !== this.node)
            });
        }
    }
}

export class AddEdgeOperation {
    constructor(sourceId, targetId) {
        this.source = sourceId;
        this.target = targetId;
    }
    name() { return `added edge (${this.source}, ${this.target})` }
    type() { return operationTypes.ADD_EDGE}
    apply(data, setData) {
        const alreadyExists = data.links.reduce((count, l) => count || ((l.source === this.source && l.target === this.target) || (l.source === this.target && l.target === this.source)), false);
        if (this.source !== this.target && !alreadyExists) {
            setData({
                nodes: data.nodes,
                links: [{target: this.target, source: this.source, color: Colors.black}, ...data.links]
            });
        }
    }
}

export class DeleteEdgeOperation {
    constructor(link) {
        this.link = link;
    }
    name() { return `deleted edge (${this.link.source.id}, ${this.link.target.id})` }
    type() { return operationTypes.DELETE_EDGE}
    apply(data, setData) {
        if(this.link){
            setData({nodes: data.nodes, links: data.links.filter(l => l !== this.link)});
        }
    }
}

export class FlipEdgeColor {
    constructor(link) {
        this.link = {...link};
    }
    name() { return `flipped edge (${this.link.source.id}, ${this.link.target.id}) to ${this.link['color'] === Colors.black ? Colors.black : Colors.red}` }
    type() { return operationTypes.FLIP_EDGE}
    apply(data, setData) {
        if(this.link){
            if (this.link['color'] === Colors.black) {
                this.link['color'] = Colors.red;
            } else {
                this.link['color'] = Colors.black;
            }
            setData({nodes: data.nodes, links: data.links.map(l => {
                if(l.source.id === this.link.source.id && l.target.id === this.link.target.id){
                    return this.link;
                }else{
                    return l;
                }
            })});
        }
    }
}

export class MergeOperation {
    constructor(toRetainId, toDeleteId) {
        this.nodeRetainId = toRetainId;
        this.nodeDeleteId = toDeleteId;
    }
    name() { return `merged nodes ${this.nodeRetain} and ${this.nodeDelete}` }
    type() { return operationTypes.MERGE}
    apply(data, setData) {
        // learn about neighborhoods
        let neighbors1 = new Map();
        let neighbors2 = new Map();
        data.links.forEach(link => {
            let sourceId = link.source.id !== undefined ? link.source.id : link.source;
            let targetId = link.target.id !== undefined ? link.target.id : link.target;
            if (targetId === this.nodeRetainId) neighbors1.set(sourceId, link.color);
            if (sourceId === this.nodeRetainId) neighbors1.set(targetId, link.color);
            if (targetId === this.nodeDeleteId) neighbors2.set(sourceId, link.color);
            if (sourceId === this.nodeDeleteId) neighbors2.set(targetId, link.color);
        });
        neighbors1.delete(this.nodeDeleteId);
        neighbors2.delete(this.nodeRetainId);
        // change the edges for the first vertex
        let newLinks = data.links.filter(link => { // retain untouched edges
            let sourceId = link.source.id !== undefined ? link.source.id : link.source;
            let targetId = link.target.id !== undefined ? link.target.id : link.target;
            const edgeSet = new Set([sourceId, targetId]);
            return !edgeSet.has(this.nodeRetainId) && !edgeSet.has(this.nodeDeleteId);
        });
        const allNeighbors = new Set([...neighbors1.keys(), ...neighbors2.keys()]);
        allNeighbors.forEach((neighbor) => { // set correctly colored new edges
            const resultBlack
                = (neighbors1.has(neighbor) && neighbors1.get(neighbor) === Colors.black)
                && (neighbors2.has(neighbor) && neighbors2.get(neighbor) === Colors.black);
            let newLink = {
                source: this.nodeRetainId,
                target: neighbor,
                color: resultBlack ? Colors.black : Colors.red,
            };
            newLinks.push(newLink);
        });
        setData({nodes: data.nodes.filter(n => n.id !== this.nodeDeleteId), links: newLinks});
    }
}
