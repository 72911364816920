import React, {useEffect, useState, useRef, useContext} from "react"
import {ForceGraph2D} from 'react-force-graph';
import * as d3 from "d3";
import {
    AddNodeOperation,
    DeleteNodeOperation,
    AddEdgeOperation,
    DeleteEdgeOperation,
    FlipEdgeColor,
    MergeOperation,
} from "./Operations"
import {SettingsContext} from "../contexts/SettingsContext";
import useKeyPress from "../hooks/useKeyPress";
import {useResizeDetector} from "react-resize-detector";
import {NODE_RADIUS, paintGraphNode} from "./utils/graphfunctions";

export default function Graph({data, apply, setId, id}) {

    const fgRef = useRef();
    const [selectedNode, setSelectedNode] = useState(null);
    const {forceLayoutActive, fixOnDrag} = useContext(SettingsContext)

    useEffect(() => {
        if (fgRef.current) {
            if (forceLayoutActive) {
                fgRef.current.resumeAnimation();
            } else {
                fgRef.current.pauseAnimation();
            }
        }
    }, [forceLayoutActive]);

    const handleLinkRightClick = link => {
        if (link) {
            apply(new DeleteEdgeOperation(link));
        }
    };

    const handleLinkClick = link => {
        if (link) {
            apply(new FlipEdgeColor(link));
        }
    };

    const handleNodeRightClick = node => {
        if (node) {
            if (selectedNode !== null) {
                if (selectedNode.id !== node.id) { // merge nodes
                    apply(new MergeOperation(selectedNode.id, node.id));
                    setSelectedNode(null);
                }
            } else { // nothing selected - delete the vertex
                apply(new DeleteNodeOperation(node));
            }
        }
    };

    const handleNodeClick = node => {
        if (node) {
            if (selectedNode === null) { // first selected node
                setSelectedNode(node);
            } else if (selectedNode.id !== node.id) { // second node - create an edge
                apply(new AddEdgeOperation(selectedNode.id, node.id));
                setSelectedNode(null);
            } else { // first node repeated - unselect
                setSelectedNode(null);
            }
        }
    };

    const escapePress = useKeyPress('Escape');
    useEffect(() => {
        if (escapePress === true) {
            setSelectedNode(null);
        }
    }, [escapePress]);

    useEffect(() => {
        const fg = fgRef.current;
        fg.d3Force('charge', d3.forceManyBody().strength(-10));
        fg.d3Force('collide', d3.forceCollide(30).strength(.4));
        fg.d3Force('link', d3.forceLink().strength(0.3));
        fg.d3Force("x", d3.forceX().strength(0.001));
        fg.d3Force("y", d3.forceY().strength(0.001));
    }, []);


    const [graphWidth,setGraphWidth] = useState(0);
    const [graphHeight,setGraphHeight] = useState(0);
    const { ref,width,height } = useResizeDetector();

    useEffect(() => {
        let zoom = fgRef.current.zoom()
        fgRef.current.centerAt((graphWidth-width)/2/zoom,(graphHeight-height)/2/zoom,0)
        const timer = setTimeout(() => {
            setGraphWidth(width)
            setGraphHeight(height)
        }, 200);
        return () => clearTimeout(timer);
    },[height,width])

    useEffect(()=>{
        let zoom = fgRef.current.zoom()
        fgRef.current.centerAt((graphWidth-width)/2/zoom,(graphHeight-height)/2/zoom,0)
    },[graphHeight,graphWidth])

    return (
        <div className={`card flex-grow-1`}>
            <div className={'card-body p-0 position-relative overflow-hidden'} ref={ref}>
                <div className={'position-absolute top-0 start-0'}>
                    <ForceGraph2D
                        ref={fgRef}
                        width={graphWidth}
                        height={graphHeight}
                        graphData={data}
                        nodeRelSize={NODE_RADIUS}
                        autoPauseRedraw={false}
                        linkWidth={3}
                        linkColor={link => link['color']}
                        nodeCanvasObject={(node,ctx)=>paintGraphNode(node,ctx,selectedNode?.id??null)}
                        onLinkClick={handleLinkClick}
                        onLinkRightClick={handleLinkRightClick}
                        onNodeClick={handleNodeClick}
                        onNodeRightClick={handleNodeRightClick}
                        onNodeDragEnd={node => {
                            if (fixOnDrag) {
                                node.fx = node.x;
                                node.fy = node.y;
                            }
                        }}
                        forceEngine={"d3"}
                        cooldownTime={Infinity}
                        d3AlphaDecay={0.006}
                        d3VelocityDecay={0.4}
                    />
                </div>
            </div>
        </div>
    );
};

