// https://dev.to/ilonacodes/frontend-shorts-how-to-read-content-from-the-file-input-in-react-1kfb

export default function FileUpload({callback}) {
    let fileReader;
    const handleFileRead = () => {
        const content = fileReader.result;
        callback(content);
    };
    const handleFileChosen = (file) => {
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    };
    return (
        <input className="form-control"
               type='file'
               id='file'
               onChange={e => handleFileChosen(e.target.files[0])}/>
    )
};
