import React from "react";

const buttons = [
    {img:'./left-click.png', alt:'left double click', info: 'create a new node'},
    {img:'./left-click.png', alt:'left click', info: 'select two nodes to create an edge, click an edge to change its color, jump in history'},
    {img:'./right-click.png', alt:'right click', info: 'no vertex selected - remove vertex; vertex is selected - merge vertex; remove an edge'},
    // {img:'./delete.png', alt:'delete', info: 'delete edges and nodes selected with right click'},
    // {img:'./enter.png', alt:'enter', info: 'merge nodes selected by left click'},
    {img:'./escape.png', alt:'escape', info: 'deselect everything'},
    {img:'./backspace.png', alt:'backspace', info: 'step back in history'}
]

export default function Info() {
    return (
        <div>
            <h6 className={'text-primary text-lowercase'}>controls</h6>
            {
                buttons.map((b,key) => (
                    <div key={key} className={'d-flex justify-content-between bg-secondary rounded mt-2'} >
                        <img src={b.img} alt={b.alt} className={'button-icon-image m-auto ms-2'} />
                        <div className={'flex-grow-1 m-2'}>
                            <h6 className={'text-primary'}>{b.alt}</h6>
                            <p className={'text-primary'}>{b.info}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}
